<template>
  <div class="block_modal_upload_layout">
    <el-upload
      class="upload-demo"
      drag
      action="https://api.gprint.ru/?action=customerFileUploadOrder"
      ref="upload"
      accept=".pdf, .cdr, .png, .jpeg, .ai, .rar, .zip, .7z, .ps, .eps"
      :limit="10"
      :headers="headersUpload"
      :data="dataUpload"
      :show-file-list="true"
      :before-remove="beforeRemove"
      :on-remove="onRemove"
      :before-upload="beforeUpload"
      :on-success="onSuccess"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :on-error="onError"
      :auto-upload="true"
      multiple
      :disabled="send_data"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Перетащите файл сюда или <em>нажмите, чтобы загрузить</em>
      </div>
      <div class="el-upload__tip block_info_count" slot="tip">
        Максимум 10 файлов.
        <span
          >Загружено файлов:
          <strong class="color_accent">{{ countUploaded }}</strong></span
        >
      </div>
      <div class="el-upload__tip" slot="tip">
        Максимальный размер файла: 300Мб
      </div>
      <div class="el-upload__tip" slot="tip">
        Форматы: .pdf, .cdr, .png, .jpeg, .ai, .rar, .zip, .7z, .ps, .eps
      </div>
    </el-upload>
    <div class="block_modal_upload_layout_button">
      <el-button
        native-type="submit"
        size="medium"
        style="width: 132px"
        plain
        @click="handleSuccess"
        :disabled="send_data"
        >Хорошо</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalUploadLayout",
  data() {
    return {
      fileList: [],
      headersUpload: {
        Authorization: "Bearer 30915f02-b0e8-4066-8540-1244d47d7304",
        Token: sessionStorage.getItem("AuthToken"),
      },
      dataUpload: {
        oid: this.$route.params.oId,
      },
      send_data: false,
    };
  },
  computed: {
    FileListOrder() {
      return this.$store.getters["Orders/FileListOrder"];
    },
    countUploaded() {
      return this.fileList.length;
    },
  },
  watch: {
    FileListOrder(files) {
      if (files.length > 0) {
        this.fileList = files.map((file) => {
          let obj = {
            name: file.filename,
            fileid: file.fileid,
          };
          return obj;
        });
      }
    },
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    beforeRemove(file) {
      if (file.fileid) {
        this.deleteFile(file);
      }
    },
    onRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleExceed() {
      // if (fileList.length > 10) {
      //   this.$message({
      //     message: "Превышено максимальное количество файлов",
      //     type: "error",
      //     offset: 20,
      //   });
      // }
      this.$message({
        message: "Превышено максимальное количество файлов",
        type: "error",
        offset: 20,
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleSuccess() {
      // this.send_data = true;
      // this.$store
      //   .dispatch("Orders/Order", this.$route.params.oId)
      //   .finally(() => {
      //     this.send_data = false;
      //     this.$emit("closeModal");
      //   });
      this.$emit("closeModal");
    },
    onSuccess(response, file, fileList) {
      file.fileid = response.response.file.fileid;
      if (response.status === 2000) {
        this.$store
          .dispatch("Orders/Order", this.$route.params.oId)
          .finally(() => {
            this.$message({
              message: `Файл ${this.truncate(file.name, 30, "...")} загружен.`,
              type: "success",
              offset: 20,
            });
          });
        this.fileList = fileList;
      }
      if (response.status === 9017) {
        let elem = fileList.pop();
        if (elem !== undefined) {
          this.fileList = fileList;
        }
        this.$message({
          message: `Файл ${this.truncate(file.name, 30, "...")} перезаписан.`,
          type: "info",
          offset: 20,
        });
      }
    },
    onError(err, file, fileList) {
      this.$message({
        message: `Ошибка при загрузке файла ${this.truncate(
          file.name,
          30,
          "..."
        )}.`,
        type: "error",
        offset: 20,
      });
      this.fileList = fileList;
    },
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    ext(name) {
      // получение расширения
      return name.match(/\.([^.]+)$|$/)[1];
    },
    beforeUpload(file) {
      let typesFile = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "application/postscript",
        "application/x-rar-compressed",
        "application/rar",
        "application/zip",
        "application/x-zip-compressed",
        "application/x-7z-compressed",
        "application/cdr",
        "application/coreldraw",
        "application/x-cdr",
        "application/x-coreldraw",
      ];
      let extFile = [
        "png",
        "jpeg",
        "pdf",
        "rar",
        "zip",
        "cdr",
        "ps",
        "eps",
        "7z",
      ];
      const isFileExt = extFile.includes(this.ext(file.name));
      // console.log(this.ext(file.name), isFileExt);
      const isFile = typesFile.includes(file.type);
      const isLt300M = file.size / 1024 / 1024 < 300;

      if (!isFile && !isFileExt) {
        this.$message({
          message: `Файл ${file.name} не разрешен для загрузки.`,
          type: "error",
          offset: 20,
        });
      }
      if (!isLt300M) {
        this.$message({
          message: "Максимально разрешенный размер 300Мб.",
          type: "error",
          offset: 20,
        });
      }
      return (isFile || isFileExt) && isLt300M;
    },
    deleteFile(file) {
      let request = {
        oid: this.$route.params.oId,
        fileId: [file.fileid],
      };
      return this.$store
        .dispatch("Orders/customerFileDeleteOrder", request)
        .then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            this.$store
              .dispatch("Orders/Order", this.$route.params.oId)
              .finally(() => {
                this.$message({
                  message: `Файл ${this.truncate(
                    file.name,
                    30,
                    "..."
                  )} удален.`,
                  type: "success",
                  offset: 20,
                });
              });
            return true;
          } else {
            return false;
          }
        })
        .then((reject) => {
          if (reject === false) {
            return Promise.reject(false);
          }
          return reject;
        });
    },
  },
  created() {
    this.send_data = true;
    this.$store
      .dispatch("Orders/customerFileListOrder", this.$route.params.oId)
      .finally(() => {
        this.send_data = false;
      });
  },
};
</script>

<style lang="sass" scoped>
.block_info_count
  display: flex
  justify-content: space-between
</style>
