<template>
  <div class="v-modal-promo">
    <div v-show="promo_block === 'main'">
      <div class="modal_promo_block">
        <div class="modal_promo_block_coin">
          <div class="modal_promo_block_coin_top">
            <p>У вас на счету: {{ this.propsForm.coinsBalance }} GPC</p>
            <p>Можно списать до {{ this.propsForm.coinsMaxWithdraw }} GPC</p>
          </div>
          <div class="modal_promo_block_coin_range" @click="activeEl('coin')">
            <vue-slider
              v-model="coinsValue"
              :disabled="rangeDisabled"
              :max="this.coinsMaxComputed"
              :lazy="true"
              :height="6"
              :dotOptions="dotOptions"
            ></vue-slider>
          </div>
          <div
            v-show="coinsValue > 0 && activeElement === 'coin'"
            class="modal_promo_block_coin_bottom"
          >
            <p class="block_coin_bottom_left">Списать:</p>
            <p class="block_coin_bottom_center">{{ coinsValue }}</p>
            <p class="block_coin_bottom_right">GPC</p>
          </div>
        </div>
        <div class="modal_promo_block_code">
          <div class="modal_promo_block_code_top">
            <p>Введите</p>
            <p>промокод</p>
          </div>
          <div
            class="modal_promo_block_code_center form-gprint"
            @click="activeEl('code')"
          >
            <input
              v-model="couponValue"
              type="text"
              minlength="6"
              maxlength="20"
              class="promo_code_input"
              :class="[
                this.activeElement === 'code'
                  ? 'active_element_promo'
                  : 'disabled_element_promo',
              ]"
            />
          </div>
          <div class="modal_promo_block_code_bottom"></div>
        </div>
      </div>
      <div class="modal_promo_button">
        <el-button
          native-type="submit"
          size="medium"
          style="width: 132px"
          :loading="send_data"
          plain
          >Применить</el-button
        >
      </div>
    </div>
    <div v-show="promo_block === 'info'">
      <div class="modal_promo_block_info">
        <p>К сожалению, такого купона</p>
        <p>нет или его нельзя применить</p>
        <p>к выбранному продукту :(</p>
      </div>
      <div class="modal_promo_button">
        <el-button
          native-type="submit"
          size="medium"
          style="width: 132px"
          :loading="send_data"
          plain
          >Ввести ещё раз</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
export default {
  name: "ModalPromo",
  components: {
    VueSlider,
  },
  props: {
    propsForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      promo_block: "info",
      send_data: false,
      coinsValue: 0,
      couponValue: "",
      rangeDisabled: false,
      activeElement: "coin",
      dotOptions: [
        {
          focusStyle: {
            borderColor: "#EA4F00",
            boxShadow: "0 0 0 5px rgba(234, 79, 0, 0.2)",
          },
          style: {
            borderColor: "#EA4F00",
          },
          disabledStyle: { backgroundColor: "#e1e1e1" },
        },
      ],
    };
  },
  computed: {
    coinsMaxComputed() {
      if (this.propsForm.coinsMaxWithdraw > this.propsForm.coinsBalance) {
        return this.propsForm.coinsBalance;
      } else return this.propsForm.coinsMaxWithdraw;
    },
  },
  methods: {
    activeEl(el) {
      if (el === "coin") {
        this.rangeDisabled = false;
      } else if (el === "code") {
        this.rangeDisabled = true;
      }
      this.activeElement = el;
    },
  },
};
</script>

<style lang="css" scoped></style>
