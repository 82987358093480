<template>
  <el-form
    :rules="rules"
    ref="AddMyDeliveryAddress"
    :label-position="labelPosition"
    :model="valuesForm"
    size="medium"
    @submit.native.prevent="onEnter"
    class="modal_body_add_delivery_address"
    :disabled="send_data"
    :status-icon="false"
  >
    <el-form-item>
      <el-select
        v-model="valuesForm.city"
        placeholder="Выберете город"
        style="width: 100%"
      >
        <el-option
          v-for="item in cities"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="street">
      <el-input
        v-model="valuesForm.street"
        placeholder="Улица"
        :disabled="valuesForm.city === ''"
      ></el-input>
    </el-form-item>
    <div class="block_number_and_office">
      <el-form-item prop="number">
        <el-input
          v-model="valuesForm.number"
          placeholder="Номер дома"
          :disabled="valuesForm.street === ''"
        ></el-input>
      </el-form-item>
      <el-form-item prop="office">
        <el-input
          v-model="valuesForm.office"
          placeholder="Кв./офис"
          :disabled="valuesForm.street === ''"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item>
      <el-input
        type="textarea"
        :rows="3"
        maxlength="150"
        :autosize="{ minRows: 3, maxRows: 6 }"
        show-word-limit
        placeholder="Напишите комментарий к адресу"
        v-model="valuesForm.comment"
        :disabled="disableField"
      >
      </el-input>
    </el-form-item>
    <el-button
      native-type="submit"
      size="medium"
      style="width: 132px; margin-top: 6px"
      @click.prevent="submitForm('AddMyDeliveryAddress')"
      :loading="send_data"
      :disabled="disableField"
      plain
      >Добавить</el-button
    >
  </el-form>
</template>

<script>
export default {
  name: "AddMyDeliveryAddress",
  data() {
    let vm = this;
    let validateINN = (rule, value, callback) => {
      if (value.length === 10 || value.length === 12) {
        vm.successInn = true;
        callback();
      } else {
        vm.successInn = false;
        callback(new Error("Формат ИНН: 10 или 12 цифр"));
      }
    };
    return {
      send_data: false,
      valuesForm: {
        city: "",
        street: "",
        number: "",
        office: "",
        comment: "",
      },
      cities: [
        {
          value: "Екатеринбург",
          label: "Екатеринбург",
        },
        {
          value: "Первоуральск",
          label: "Первоуральск",
        },
        {
          value: "Ревда",
          label: "Ревда",
        },
        {
          value: "Полевской",
          label: "Полевской",
        },
        {
          value: "Тюмень",
          label: "Тюмень",
        },
        {
          value: "Екатеринбург2",
          label: "Екатеринбург",
        },
        {
          value: "Первоуральск2",
          label: "Первоуральск",
        },
        {
          value: "Ревда2",
          label: "Ревда",
        },
        {
          value: "Полевской2",
          label: "Полевской",
        },
        {
          value: "Тюмень2",
          label: "Тюмень",
        },
      ],
      labelPosition: "top",
      rules: {
        inn: [
          {
            required: true,
            message: "Пожалуйста укажите ИНН",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            validator: validateINN,
            trigger: "change",
            transform(value) {
              return value.trim();
            },
          },
        ],
        bik: [
          {
            required: true,
            message: "Пожалуйста укажите БИК",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            min: 9,
            message: "Формат БИК: 9 цифр",
            trigger: "change",
          },
        ],
        current_account_number: [
          {
            required: true,
            message: "Пожалуйста укажите Расчетный счет",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            min: 20,
            message: "Формат Расчетный счет: 20 цифр",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    disableField() {
      return (
        this.valuesForm.city === "" ||
        this.valuesForm.street === "" ||
        this.valuesForm.number === ""
      );
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.$emit("closeModal");
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
