<template>
  <div class="v-modal-changed-customer">
    <div class="modal_changed_customer">
      <p
        v-for="(customer, i) in Companies"
        :key="i"
        class="changed_customer_link"
        @click="selectCustomer(customer.companyid)"
      >
        {{ customer.name }}
      </p>
    </div>
    <div class="changed_add_new" v-if="isAdmin">
      <p>или добавьте нового.</p>
    </div>
    <div class="changed_customer_button" v-if="isAdmin">
      <el-button
        native-type="submit"
        size="medium"
        style="width: 160px"
        :disabled="send_data"
        plain
        @click="addNew"
        >Добавить нового</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalChangeCustomer",
  data() {
    return {
      send_data: false,
    };
  },
  computed: {
    Companies() {
      return this.$store.getters["Settings/CompanyList"];
    },
    isAdmin() {
      return this.$store.getters["Main/IsAdmin"];
    },
  },
  methods: {
    addNew() {
      this.$emit("showModalAddLegalEntity");
    },
    selectCustomer(companyId) {
      this.send_data = true;
      this.openLoading("Отправка запроса...");
      let request = {
        oid: this.$route.params.oId,
        company: companyId,
      };
      this.$store.dispatch("Orders/setCompany", request).then((response) => {
        if (response.data.status && response.data.status === 2000) {
          this.$store
            .dispatch("Orders/Order", this.$route.params.oId)
            .then((response) => {
              if (response.data.status && response.data.status === 2000) {
                this.$emit("closeModal");
                this.$notify({
                  title: "Success",
                  message: "Заказчик успешно изменен!",
                  type: "success",
                  offset: 85,
                  duration: 6000,
                });
              }
            })
            .finally(() => {
              this.send_data = false;
              this.closeLoading();
            });
        }
      });
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".v-modal-changed-customer",
        text: text,
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 1)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
