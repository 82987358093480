<template>
  <el-form
    :rules="rules"
    ref="AddUser"
    :label-position="labelPosition"
    :model="valuesForm"
    size="medium"
    @submit.native.prevent="onEnter"
    class="modal_body_add_user"
    :disabled="send_data"
  >
    <div class="block_1">
      <el-form-item label="Введите Фамилию:" prop="surname">
        <el-input v-model="valuesForm.surname" placeholder="Иванов"></el-input>
      </el-form-item>
      <el-form-item label="Введите Имя:" prop="name">
        <el-input v-model="valuesForm.name" placeholder="Иван"></el-input>
      </el-form-item>
      <el-form-item label="Введите телефон:" prop="phone">
        <el-input
          type="phone"
          v-model="valuesForm.phone"
          v-mask="'+7 ~## ### ## ##'"
          placeholder="+7 900 000 00 00"
        ></el-input>
      </el-form-item>
      <el-form-item label="Введите E-mail:" prop="email">
        <el-input
          type="email"
          v-model="valuesForm.email"
          placeholder="ivan@ivanov.ru"
        ></el-input>
      </el-form-item>
    </div>
    <div class="block_2">
      <el-form-item label="Введите логин:" prop="login">
        <el-input v-model="valuesForm.login" placeholder="Login"></el-input>
      </el-form-item>
      <el-form-item label="Введите пароль:" prop="password">
        <el-input
          type="password"
          v-model="valuesForm.password"
          placeholder="************"
          show-password
        ></el-input>
      </el-form-item>
      <div class="switch_settings_block">
        <el-switch
          :width="switchWidth"
          v-model="valuesForm.infosms"
          active-text="СМС уведомления"
        />
        <el-switch
          :width="switchWidth"
          v-model="valuesForm.infoemail"
          active-text="E-mail уведомления"
        />
      </div>
    </div>
    <div class="block_3">
      <el-button
        native-type="submit"
        size="medium"
        style="width: 132px"
        @click.prevent="submitForm('AddUser')"
        :loading="send_data"
        plain
        >Добавить</el-button
      >
    </div>
  </el-form>
</template>

<script>
export default {
  name: "ModalAddUser",
  data() {
    let validateFIO = (rule, value, callback) => {
      if (this.regFio(value) || value === "") {
        callback();
      } else {
        callback(new Error("ФИО начинается с заглавной"));
      }
    };
    return {
      send_data: false,
      valuesForm: {
        id: 0,
        name: "",
        surname: "",
        phone: "",
        email: "",
        login: "",
        password: "",
        infosms: false,
        infoemail: true,
      },
      labelPosition: "top",
      rules: {
        surname: [
          {
            required: true,
            message: "Пожалуйста введите Фамилию",
            trigger: "change",
          },
          {
            min: 3,
            max: 15,
            message: "Длинна Фамилии от 3 до 15 символов",
            trigger: "blur",
          },
          // {
          //   validator: validateFIO,
          //   trigger: "change",
          //   transform(value) {
          //     return value.trim();
          //   },
          // },
        ],
        name: [
          {
            required: true,
            message: "Пожалуйста введите Имя",
            trigger: "change",
          },
          {
            min: 3,
            max: 15,
            message: "Длинна Имени от 3 до 15 символов",
            trigger: "blur",
          },
          // {
          //   validator: validateFIO,
          //   trigger: "change",
          //   transform(value) {
          //     return value.trim();
          //   },
          // },
        ],
        patronymic: [
          {
            validator: validateFIO,
            trigger: "change",
            transform(value) {
              return value.trim();
            },
          },
        ],
        phone: [
          {
            required: true,
            message: "Пожалуйста укажите телефон",
            trigger: "change",
          },
          {
            min: 11,
            message: "Формат телефона: +7 900 000 00 00 (11 цифр)",
            trigger: "blur",
            transform(value) {
              return value.trim().replace(/\+7/, "7").replace(/\s/g, "");
            },
          },
        ],
        email: [
          {
            required: true,
            message: "Пожалуйста укажите Email",
            trigger: "change",
          },
          {
            type: "email",
            message: "Неверный формат Email",
            trigger: "blur",
          },
        ],
        login: [
          {
            required: true,
            message: "Пожалуйста укажите логин",
            trigger: "change",
          },
          {
            min: 6,
            max: 15,
            message: "Длинна логина от 6 до 15 символов",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Пожалуйста укажите пароль",
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: "Длинна пароля от 6 до 20 символов",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      switchWidth: 32,
    };
  },
  methods: {
    regFio(value) {
      var regExp = /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,})?$/;
      return regExp.test(value);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.addUser();
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
    addUser() {
      this.send_data = true;
      this.$store
        .dispatch("Settings/customerUserUpdate", this.valuesForm)
        .then((response) => {
          if (response.data.status === 2000) {
            this.$emit("closeModal");
          }
        })
        .finally(() => {
          this.send_data = false;
        });
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
