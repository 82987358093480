<template>
  <div class="v-modal-changed-period">
    <div class="modal_changed_period">
      <p>Срок готовности заказа:</p>
      <p class="color_accent">{{ ReceiveDate }}</p>
    </div>
    <div class="changed_period_button">
      <el-button
        native-type="submit"
        size="medium"
        style="width: 132px"
        :loading="send_data"
        plain
        @click="good"
        >Хорошо</el-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment";
export default {
  name: "ModalChangedPeriod",
  props: {
    propsForm: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      send_data: false,
    };
  },
  computed: {
    ReceiveDate() {
      if (this.propsForm.readydate) {
        return (
          moment(this.propsForm.readydate).format("DD MMMM YYYY") +
          " после " +
          moment(this.propsForm.readydate).format("HH:mm")
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    good() {
      this.$store.commit("Orders/KeyOrdersComponent", Math.random());
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped></style>
