<template>
  <transition name="modal">
    <div
      v-if="show"
      class="modal-shadow"
      @click.self="clickingOutsideTheModalWindow"
    >
      <div class="modal">
        <div v-if="showTheCloseButton" class="modal-close" @click="closeModal">
          &#10006;
        </div>
        <div class="modal-slots">
          <div v-show="showLogo" class="modal-logo"></div>
          <div class="modal-title">
            <p>{{ title }}</p>
            <p>{{ subtitle }}</p>
          </div>
          <slot name="body">
            <component
              :is="modalBody"
              @closeModal="closeModal"
              @showModalAddLegalEntity="showModalAddLegalEntity"
              :props-form="propsForm"
            >
            </component>
          </slot>
          <!--          <slot name="footer">-->
          <!--            <div class="modal-footer"></div>-->
          <!--          </slot>-->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Default from "@/components/ModalDefault";
import AddUser from "@/components/ModalAddUser";
import EditUser from "@/components/ModalEditUser";
import AddLegalEntity from "@/components/ModalAddLegalEntity";
import AddMyDeliveryAddress from "@/components/ModalAddMyDeliveryAddress";
import CommentsCrm from "@/components/ModalCommentsCrm";
import Promo from "@/components/ModalPromo";
import UploadLayout from "@/components/ModalUploadLayout";
import ChangedPeriod from "@/components/ModalChangedPeriod";
import ChangeCustomer from "@/components/ModalChangeCustomer";
import MethodReceivingOrder from "@/components/ModalMethodReceivingOrder";
import EditNote from "@/components/ModalEditNote";
export default {
  name: "Modal",
  components: {
    Default,
    AddUser,
    EditUser,
    AddLegalEntity,
    AddMyDeliveryAddress,
    CommentsCrm,
    Promo,
    UploadLayout,
    ChangedPeriod,
    ChangeCustomer,
    MethodReceivingOrder,
    EditNote,
  },
  props: {
    title: {
      type: String,
      default: "Заголовок",
    },
    subtitle: {
      type: String,
      default: "",
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    closeWhenClickedOutside: {
      type: Boolean,
      default: true,
    },
    showTheCloseButton: {
      type: Boolean,
      default: true,
    },
    modalBody: {
      type: String,
      default: "Default",
    },
    propsForm: {
      type: [Object, Array],
    },
  },
  data: function () {
    return {
      show: false,
    };
  },
  computed: {},
  methods: {
    closeModal: function () {
      this.show = false;
    },
    showModalAddLegalEntity: function () {
      this.show = false;
      this.$emit("showModalAddLegalEntity");
    },
    clickingOutsideTheModalWindow() {
      if (this.closeWhenClickedOutside) {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/modal_body.sass"
</style>
