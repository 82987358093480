<template>
  <div class="v-modal-method-receiving-order">
    <div class="method_receiving_order_block">
      <div class="method_receiving_order_block_top">
        <el-radio-group v-model="radio" size="medium" class="radio_group_flex">
          <el-radio
            :label="method.type"
            v-for="(method, index) in Receive"
            :key="index"
            :disabled="!method.status || send_data"
            >{{ method.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <transition name="component-fade" mode="out-in" appear>
        <component
          v-loading="send_data"
          element-loading-text="Меняем способ получения заказа.."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 1)"
          :is="MethodReceivingOrderComponent"
          :points="points"
          :CurrentReceive="CurrentReceive"
          @selectedDataId="selectedDataId"
          @setRecipientFormRef="setRecipientFormRef"
        >
          <router-view />
        </component>
      </transition>
      <div class="method_receiving_order_block_bottom">
        <div class="block_info">
          <!--          <p>Заказ доставят 30.10.2020 после 15 ч.</p>-->
          <p v-show="CurrentReceive.type === 'delivery'">
            Стоимость доставка курьером:
          </p>
          <p v-show="cdekDays !== '' && cdekPrice !== undefined">
            Срок доставки: {{ cdekDays }}
          </p>
        </div>
        <div
          :class="[
            CurrentReceive.summ !== undefined || cdekPrice !== undefined
              ? 'button_info'
              : 'button_info_no_active',
          ]"
        >
          <span v-if="CurrentReceive.summ !== undefined">
            {{
              CurrentReceive.summ === 0
                ? "Бесплатно"
                : CurrentReceive.summ + " ₽"
            }}
          </span>
          <span v-else-if="cdekPrice !== undefined">
            {{ cdekPrice + " ₽" }}
          </span>
        </div>
        <el-button
          :disabled="
            requestDataSetReceive === undefined ||
            (addressDelivery === undefined &&
              CurrentReceive.type === 'delivery')
          "
          native-type="submit"
          size="medium"
          style="width: 132px"
          :loading="send_data"
          @click="setReceive"
          plain
          >Выбрать</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Pickup from "@/components/MethodReceivingOrder/MethodReceivingOrderPickup";
import PickPoints from "@/components/MethodReceivingOrder/MethodReceivingOrderPickPoints";
import DeliveryByCourier from "@/components/MethodReceivingOrder/MethodReceivingOrderDeliveryByCourier";
import MyAddresses from "@/components/MethodReceivingOrder/MethodReceivingMyAddresses";
import Cdek from "@/components/MethodReceivingOrder/MethodReceivingOrderCdek";
export default {
  name: "ModalMethodReceivingOrder",
  components: { Pickup, PickPoints, DeliveryByCourier, MyAddresses, Cdek },
  data() {
    return {
      radio: "pickup",
      send_data: false,
      request_data: {},
      recipientFormRef: null,
    };
  },
  computed: {
    points() {
      let obj = {};
      this.Receive.forEach((method) => {
        if (method.type === this.radio && method.points !== undefined) {
          obj = method.points;
        }
      });
      return obj;
    },
    id_list() {
      return "id_" + this.radio;
    },
    Methods() {
      return this.$store.getters["MethodReceivingOrder/getMethods"];
    },
    Receive() {
      return this.$store.getters["Orders/Receive"];
    },
    cdekDays() {
      return this.$store.getters["Orders/cdekDays"];
    },
    cdekPrice() {
      return this.$store.getters["Orders/cdekPrice"];
    },
    requestDataSetReceive() {
      return this.$store.getters["Orders/requestDataSetReceive"];
    },
    addressDelivery() {
      return this.$store.getters["Dadata/addressDelivery"];
    },
    MethodReceivingOrderComponent() {
      if (this.radio === "pickup") return "Pickup";
      if (this.radio === "pickpoint") return "PickPoints";
      if (this.radio === "delivery") return "DeliveryByCourier";
      if (this.radio === "myAddresses") return "MyAddresses";
      if (this.radio === "cdek") return "Cdek";
      else return "Pickup";
    },

    CurrentReceive() {
      if (this.Receive.length > 0) {
        return this.Receive.filter((item) => {
          return item.type === this.radio;
        })[0];
      } else {
        return {};
      }
    },
  },
  methods: {
    setRecipientFormRef(ref) {
      this.recipientFormRef = ref;
    },
    getReceive() {
      this.$store.dispatch("Orders/getReceive", this.$route.params.oId);
    },
    selectedDataId(data_id) {
      if (data_id) {
        let request_data = {
          oid: this.$route.params.oId,
          type: this.CurrentReceive.type,
          id: data_id,
          address: {},
        };
        this.$store.commit("Orders/requestDataSetReceive", request_data);
      }
    },
    setReceive() {
      if (this.recipientFormRef) {
        this.recipientFormRef.validate((valid) => {
          if (valid) {
            this.send_data = true;
            this.$store.dispatch("Orders/setReceive").then((response) => {
              if (response.data.status && response.data.status === 2000) {
                this.$store
                  .dispatch("Orders/Order", this.$route.params.oId)
                  .then((response) => {
                    if (response.data.status && response.data.status === 2000) {
                      this.$store
                        .dispatch("Orders/Order", this.$route.params.oId)
                        .then((response) => {
                          if (
                            response.data.status &&
                            response.data.status === 2000
                          ) {
                            this.$emit("closeModal");
                            this.$notify({
                              title: "Success",
                              message: "Способ получения заказа изменен!",
                              type: "success",
                              offset: 85,
                              duration: 6000,
                            });
                          }
                        })
                        .finally(() => {
                          this.send_data = false;
                        });
                    }
                  });
              }
            });
          } else {
            console.log("Ошибка в данных формы!");
            return false;
          }
        });
      } else {
        this.send_data = true;
        this.$store.dispatch("Orders/setReceive").then((response) => {
          if (response.data.status && response.data.status === 2000) {
            this.$store
              .dispatch("Orders/Order", this.$route.params.oId)
              .then((response) => {
                if (response.data.status && response.data.status === 2000) {
                  this.$store
                    .dispatch("Orders/Order", this.$route.params.oId)
                    .then((response) => {
                      if (
                        response.data.status &&
                        response.data.status === 2000
                      ) {
                        this.$emit("closeModal");
                        this.$notify({
                          title: "Success",
                          message: "Способ получения заказа изменен!",
                          type: "success",
                          offset: 85,
                          duration: 6000,
                        });
                      }
                    })
                    .finally(() => {
                      this.send_data = false;
                    });
                }
              });
          }
        });
      }
    },
  },
  mounted() {
    // this.getReceive();
  },
};
</script>

<style scoped>
.button_info_no_active {
  width: 132px;
  height: 36px;
  margin-right: 24px;
}
</style>
