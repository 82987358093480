<template>
  <div v-if="showMap" ref="yandexMap" class="v-map" :key="city"></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "v-map",
  props: {
    points: {
      type: Object,
      default() {
        return {};
      },
    },
    id_list: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "Geo";
      },
    },
    delivery: {
      type: String,
      default() {
        return "";
      },
    },
    city: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      mapOptions: {
        center: [60.626122, 56.844274],
        zoom: 13,
        controls: ["zoomControl"],
      },
      placemarkList: {},
      pickPoint: "",
      showMap: false,
      dataId: 0,
      POINTS: {},
      mapPickPoint: {},
    };
  },
  methods: {
    ...mapActions([""]),
    initMap() {
      let vm = this;
      const $ = window.$;
      // eslint-disable-next-line no-undef
      vm.mapPickPoint = new ymaps.Map(vm.$refs.yandexMap, {
        ...this.mapOptions,
      });
      // eslint-disable-next-line no-undef,no-unused-vars
      let objectManager = new ymaps.ObjectManager();

      // Коллекция для геообъектов группы.
      let collection;
      // eslint-disable-next-line no-undef
      collection = new ymaps.GeoObjectCollection(null, {
        preset: "islands#orangeDotIcon",
      });
      // Добавляем коллекцию на карту.
      vm.mapPickPoint.geoObjects.add(collection);

      vm.POINTS.features.forEach(function (obj) {
        // Создаем метку.
        let placemark;
        // eslint-disable-next-line no-undef
        placemark = new ymaps.Placemark(obj.geometry.coordinates, {
          balloonContent: obj.properties.description,
          dataId: obj.id,
        });

        if (!vm.placemarkList[obj.id]) vm.placemarkList[obj.id] = placemark;

        // Добавляем метку в коллекцию.
        collection.add(placemark);

        $("#" + vm.id_list).append(
          '<li data-id="' +
            obj.id +
            '" tabindex="1">' +
            obj.properties.description +
            "</li>"
        );
      });

      // Добавляем коллекцию на карту.
      vm.mapPickPoint.geoObjects.add(collection);

      // Клик на адрес
      $(document).on("click keyup", "#" + vm.id_list + " li", function (event) {
        event.preventDefault();
        let dataId = $(this).attr("data-id");
        vm.pickPoint = $(this).text();
        vm.dataId = dataId;
        // vm.SET_REQUEST_DATA_ID_TO_STATE(dataId);
        vm.$emit("selectedDataId", vm.dataId);

        var _this = $(this);
        _this.parent().children().removeClass("active");
        _this.addClass("active");

        vm.placemarkList[dataId].events.fire("click");
      });
      // При нажатии на метку делаем активным пункт в левом блоке и переходим к нему
      collection.events.add("click", function (e) {
        const placemark1 = e.get("target");
        const id = placemark1.properties.get("dataId");

        vm.pickPoint = placemark1.properties.get("balloonContent");
        vm.dataId = id;
        // vm.SET_REQUEST_DATA_ID_TO_STATE(id);
        vm.$emit("selectedDataId", id);

        let _t = $("#" + vm.id_list + " li");
        _t.parent().children().removeClass("active");
        $("#" + vm.id_list + " li" + "[data-id='" + id + "']").addClass(
          "active"
        );
        $(".method_receiving_order_block_center_left").animate(
          {
            scrollTop:
              $("li" + "[data-id='" + id + "']").offset().top -
              $("#" + vm.id_list).offset().top,
          },
          500
        );
      });

      /*Устанавливаем первый элемент li активным*/
      let data_Id = $("#" + vm.id_list + " li")
        .first()
        .attr("data-id");
      vm.placemarkList[data_Id].events.fire("click");
    },
    // initMapCity() {
    //   let vm = this;
    //   const $ = window.$;
    //   // eslint-disable-next-line no-undef
    //   var myMap = new ymaps.Map(
    //     vm.$refs.yandexMap,
    //     {
    //       center: [60.626122, 56.844274],
    //       zoom: 13,
    //       controls: ["geolocationControl", "searchControl"],
    //     }
    //     // {
    //     //   restrictMapArea: [
    //     //     [55.7, 37.6],
    //     //     [55.8, 37.7],
    //     //   ],
    //     // }
    //   );
    //
    //   //eslint-disable-next-line no-undef
    //   // var searchControl = new ymaps.control.SearchControl({
    //   //   options: {
    //   //     float: "left",
    //   //     floatIndex: 100,
    //   //     kind: "house",
    //   //   },
    //   // });
    //   // myMap.controls.add(searchControl);
    //   //
    //   // console.log(myMap.getBounds());
    //   let searchControl = myMap.controls.get("searchControl");
    //   searchControl.options.set({
    //     noPlacemark: true,
    //     placeholderContent: "Введите адрес доставки",
    //   });
    //
    //   //Запросим у openstreetmap полигон, для от рисовки на карте
    //
    //   var url = "https://nominatim.openstreetmap.org/search";
    //
    //   $.getJSON(url, {
    //     q: "Екатеринбург, Россия",
    //     format: "json",
    //     polygon_geojson: 1,
    //   }).then(
    //     function (data) {
    //       console.log(data);
    //
    //       $.each(data, function (ix, place) {
    //         if ("relation" == place.osm_type && "place" == place.class) {
    //           if (place.geojson.type == "MultiPolygon") {
    //             //Если openstreetmap прислал множество полигонов
    //
    //             place.geojson.coordinates.forEach(function (item) {
    //               // eslint-disable-next-line no-undef
    //               myMap.geoObjects.add(new ymaps.Polygon(item));
    //             });
    //           } else {
    //             //Если openstreetmap один полигон
    //
    //             // eslint-disable-next-line no-undef
    //             var p = new ymaps.Polygon(place.geojson.coordinates);
    //
    //             myMap.geoObjects.add(p);
    //           }
    //         }
    //       });
    //
    //       //отцентруем полигоны и метки чтобы они все помещались на видимую часть карты
    //
    //       myMap.setBounds(myMap.geoObjects.getBounds(), {
    //         checkZoomRange: true,
    //       });
    //     },
    //     function (err) {
    //       console.log(err);
    //     }
    //   );
    //
    //   // // Увеличим значение максимальной ширины для поисковой строки в состоянии 'large'.
    //   // searchControl.options.set({
    //   //   // Максимальная ширина для трех состояний: [30, 72, 315].
    //   //   // Изменим ширину для состояния 'large'.
    //   //   searchControlMaxWidth: [30, 72, 500],
    //   //   // Расширим поисковую строку до максимального размера.
    //   //   fitMaxWidth: true,
    //   // });
    //
    //   // eslint-disable-next-line no-undef
    //   ymaps
    //     .geocode(this.city, {
    //       results: 1,
    //     })
    //     .then(function (res) {
    //       // Выбираем первый результат геокодирования.
    //       var firstGeoObject = res.geoObjects.get(0),
    //         // Координаты геообъекта.
    //         // eslint-disable-next-line no-unused-vars
    //         coords = firstGeoObject.geometry.getCoordinates(),
    //         // Область видимости геообъекта.
    //         bounds = firstGeoObject.properties.get("boundedBy");
    //
    //       firstGeoObject.options.set("preset", "islands#orangeDotIcon");
    //       // Получаем строку с адресом и выводим в иконке геообъекта.
    //       firstGeoObject.properties.set(
    //         "iconCaption",
    //         firstGeoObject.getAddressLine()
    //       );
    //       // Добавляем первый найденный геообъект на карту.
    //       myMap.geoObjects.add(firstGeoObject);
    //       // eslint-disable-next-line no-undef
    //       // Масштабируем карту на область видимости геообъекта.
    //       myMap.setBounds(bounds, {
    //         // Проверяем наличие тайлов на данном масштабе.
    //         checkZoomRange: true,
    //       });
    //     });
    // },
  },
  watch: {
    points: function (value) {
      this.POINTS = value;
    },
  },
  mounted() {
    this.showMap = true;
    this.POINTS = this.points;
  },
  updated() {
    if (this.type === "delivery") {
      // eslint-disable-next-line no-undef
      ymaps.ready(() => this.initMapCity());
    } else {
      // eslint-disable-next-line no-undef
      ymaps.ready(() => this.initMap());
    }
    this.placemarkList = {};
  },
  destroyed() {
    let vm = this;
    const $ = window.$;
    $(document).off("click", "#" + vm.id_list + " li");
    vm.mapPickPoint.destroy();
    this.$store.commit("Orders/cdekPrice", undefined);
  },
};
</script>

<style scoped></style>
