<template>
  <div class="method_receiving_order_block_center">
    <div class="method_receiving_order_block_center_left">
      <el-form
        ref="DeliveryAByCourier"
        :model="valuesForm"
        size="medium"
        class="delivery_search_address"
        :status-icon="false"
      >
        <el-form-item>
          <el-select v-model="city_value" placeholder="Выберите город">
            <el-option
              v-for="(item, index) in CurrentReceive.citys"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="street">
          <el-autocomplete
            v-model="valuesForm.street"
            :fetch-suggestions="streetSearch"
            placeholder="Улица"
            :disabled="city_value === ''"
            style="width: 100%"
            :highlight-first-item="true"
            :trigger-on-focus="false"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <div class="block_number_and_office">
          <el-form-item prop="number">
            <el-autocomplete
              v-model="valuesForm.number"
              placeholder="Номер дома"
              :disabled="valuesForm.street === ''"
              :highlight-first-item="true"
              :trigger-on-focus="false"
              :fetch-suggestions="numberSearch"
              @select="handleSelectNumber"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="office">
            <el-input
              v-model="valuesForm.office"
              placeholder="Кв./офис"
              :disabled="valuesForm.street === ''"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-input
            type="textarea"
            :rows="3"
            maxlength="150"
            :autosize="{ minRows: 5, maxRows: 5 }"
            show-word-limit
            placeholder="Напишите комментарий к адресу"
            v-model="valuesForm.comment"
            :disabled="disableField"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="method_receiving_order_block_center_right">
      <div
        ref="yandexMap"
        class="v-map"
        style="width: 566px; height: 319px"
      ></div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
export default {
  name: "MethodReceivingOrderDeliveryByCourier",
  props: {
    points: {
      type: Object,
      default() {
        return {};
      },
    },
    id_list: {
      type: String,
      default() {
        return "";
      },
    },
    CurrentReceive: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      data_id: 0,
      city_value: "",
      yandex_search: "",
      myMap: {},
      suggestView: {},
      valuesForm: {
        street: "",
        number: "",
        office: "",
        comment: "",
      },
      locations: {},
      selectedStreet: false,
      selectedNumber: false,
    };
  },
  components: {},
  computed: {
    disableField() {
      return (
        this.city_value === "" ||
        this.valuesForm.street === "" ||
        this.valuesForm.number === ""
      );
    },
    currentCity() {
      return this.CurrentReceive.citys.find((item) => {
        return item.name === this.city_value;
      });
    },
  },
  methods: {
    streetSearch(queryString, cb) {
      if (queryString.length >= 2) {
        let request = {
          query: this.valuesForm.street,
          count: 4,
          restrict_value: true,
          from_bound: { value: "street" },
          to_bound: { value: "street" },
          locations: this.currentCity.locations,
        };
        this.$store
          .dispatch("Dadata/getAddress", request)
          .then((response) => {
            if (response.data.suggestions.length > 0) {
              cb(response.data.suggestions);
            }
          })
          .catch((error) => {
            return error;
          });
      } else {
        this.$store.commit("Dadata/street_fias_id", "");
        this.$store.commit("Dadata/qc_geo", undefined);
        this.$store.commit("Dadata/fias_level", undefined);
        this.$store.commit("Dadata/addressDelivery", undefined);
      }
      if (queryString) {
        this.valuesForm.number = "";
        this.valuesForm.office = "";
        this.valuesForm.comment = "";
      }
      this.selectedCity();
    },
    numberSearch(queryString, cb) {
      if (queryString.length >= 1) {
        let request = {
          query: this.valuesForm.number,
          count: 4,
          restrict_value: true,
          from_bound: { value: "house" },
          locations: [
            { street_fias_id: this.$store.getters["Dadata/street_fias_id"] },
          ],
        };
        this.$store
          .dispatch("Dadata/getAddress", request)
          .then((response) => {
            if (response.data.suggestions) {
              cb(response.data.suggestions);
            }
          })
          .catch((error) => {
            return error;
          });
      } else {
        this.$store.commit("Dadata/qc_geo", undefined);
        this.$store.commit("Dadata/fias_level", undefined);
        this.$store.commit("Dadata/addressDelivery", undefined);
      }
      this.selectedCity();
    },
    handleSelect(item) {
      this.selectedStreet = true;
      this.$emit("selectedStreet", true);
      if (item.data) {
        this.$store.commit("Dadata/street_fias_id", item.data.street_fias_id);
      }
      if (item.value) {
        this.suggestions = [];
      }
      this.selectedCity();
    },
    handleSelectNumber(suggestion) {
      this.selectedNumber = true;
      this.$emit("selectedNumber", true);
      this.$store.commit("Dadata/qc_geo", suggestion.data.qc_geo);
      this.$store.commit("Dadata/fias_level", suggestion.data.fias_level);
      if (
        Number(suggestion.data.fias_level) === 8 ||
        Number(suggestion.data.qc_geo) === 0
      ) {
        this.$store.commit(
          "Dadata/addressDelivery",
          suggestion.unrestricted_value
        );
        this.setAddress(suggestion.unrestricted_value);
        this.selectedCity();
      }
    },
    selectedDataId(data_id) {
      this.data_id = data_id;
    },
    selectedCity() {
      let request_data = {
        oid: this.$route.params.oId,
        type: this.CurrentReceive.type,
        id: 0,
        address: {
          city: this.city_value,
          street: this.valuesForm.street,
          number: this.valuesForm.number,
          office: this.valuesForm.office,
          desc: this.valuesForm.comment,
        },
      };
      this.$store.commit("Orders/requestDataSetReceive", request_data);
    },
    destroyMap() {
      const myMap = this.myMap;
      if (myMap.destroy()) {
        myMap.destroy();
      }
    },
    initMap() {
      let vm = this;
      // eslint-disable-next-line no-undef
      this.myMap = new ymaps.Map(vm.$refs.yandexMap, {
        center: [60.626122, 56.844274],
        zoom: 10,
        controls: [],
      });
    },
    setCity(city) {
      const myMap = this.myMap;
      // eslint-disable-next-line no-undef
      ymaps
        .geocode("Россия, Свердловская область, " + city, {
          results: 1,
        })
        .then(function (res) {
          // Выбираем первый результат геокодирования.
          var firstGeoObject = res.geoObjects.get(0),
            // Координаты геообъекта.
            // eslint-disable-next-line no-unused-vars
            coords = firstGeoObject.geometry.getCoordinates(),
            // Область видимости геообъекта.
            bounds = firstGeoObject.properties.get("boundedBy");

          // firstGeoObject.options.set("preset", "islands#orangeDotIcon");
          // // Получаем строку с адресом и выводим в иконке геообъекта.
          // firstGeoObject.properties.set(
          //   "iconCaption",
          //   firstGeoObject.getAddressLine()
          // );
          // Добавляем первый найденный геообъект на карту.
          // myMap.geoObjects.add(firstGeoObject);
          // eslint-disable-next-line no-undef
          // Масштабируем карту на область видимости геообъекта.
          myMap.setBounds(bounds, {
            // Проверяем наличие тайлов на данном масштабе.
            checkZoomRange: true,
          });
        });
    },
    setAddress(address) {
      const myMap = this.myMap;
      myMap.geoObjects.removeAll();
      // eslint-disable-next-line no-undef
      ymaps
        .geocode(address, {
          results: 1,
        })
        .then(function (res) {
          // Выбираем первый результат геокодирования.
          var firstGeoObject = res.geoObjects.get(0),
            // Координаты геообъекта.
            // eslint-disable-next-line no-unused-vars
            coords = firstGeoObject.geometry.getCoordinates(),
            // Область видимости геообъекта.
            bounds = firstGeoObject.properties.get("boundedBy");

          firstGeoObject.options.set("preset", "islands#orangeDotIcon");
          // // Получаем строку с адресом и выводим в иконке геообъекта.
          firstGeoObject.properties.set(
            "iconCaption",
            firstGeoObject.getAddressLine()
          );
          // Добавляем первый найденный геообъект на карту.
          myMap.geoObjects.add(firstGeoObject);
          // eslint-disable-next-line no-undef
          // Масштабируем карту на область видимости геообъекта.
          myMap.setBounds(bounds, {
            // Проверяем наличие тайлов на данном масштабе.
            checkZoomRange: true,
          });
          firstGeoObject.balloon.open();
        });
    },
  },
  watch: {
    city_value(value) {
      this.destroyMap();
      this.initMap();
      this.setCity(value);
      this.valuesForm.street = "";
      this.valuesForm.number = "";
      this.selectedCity();
    },
  },
  mounted() {
    this.city_value = this.CurrentReceive.citys[0].name;
    this.setCity(this.CurrentReceive.citys[0].name);
    this.initMap();
    this.$store.commit("Dadata/street_fias_id", "");
    this.$store.commit("Dadata/qc_geo", undefined);
    this.$store.commit("Dadata/fias_level", undefined);
    this.$store.commit("Dadata/addressDelivery", undefined);
  },
  destroyed() {
    this.$store.commit("Dadata/street_fias_id", "");
    this.$store.commit("Dadata/qc_geo", undefined);
    this.$store.commit("Dadata/fias_level", undefined);
    this.$store.commit("Dadata/addressDelivery", undefined);
    this.myMap.destroy();
  },
};
</script>

<style lang="sass" scoped>
.delivery_search_address
  display: flex
  flex-direction: column
  gap: 12px
.block_number_and_office
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 24px
</style>
