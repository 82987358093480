<template>
  <el-form
    ref="EditNote"
    :model="valuesForm"
    size="medium"
    @submit.native.prevent="onEnter"
    class="modal_body_edit_note"
    :disabled="send_data"
    :status-icon="false"
  >
    <el-form-item class="block_textarea_comment">
      <el-input
        type="textarea"
        :rows="4"
        maxlength="150"
        :autosize="{ minRows: 4, maxRows: 10 }"
        show-word-limit
        placeholder="Напишите комментарий к расчету"
        v-model="valuesForm.desc"
      >
      </el-input>
    </el-form-item>
    <el-button
      native-type="submit"
      size="medium"
      style="width: 132px"
      @click.prevent="submitForm('EditNote')"
      :loading="send_data"
      plain
      >Сохранить</el-button
    >
    <!--    <div class="block_textarea_comment"></div>-->
    <!--    <div class="block_button"></div>-->
  </el-form>
</template>

<script>
export default {
  name: "ModalEditNote",
  props: {
    propsForm: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      valuesForm: {
        desc: "",
      },
      send_data: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setNote();
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
    setNote() {
      this.send_data = true;
      let request = {
        oid: this.$route.params.oId,
        desc: this.valuesForm.desc,
      };
      this.$store
        .dispatch("Orders/customerOrderDescSet", request)
        .then((response) => {
          if (response.data.status && response.data.status === 2000) {
            this.$notify({
              title: "Success",
              message: "Данные успешно сохранены!",
              type: "success",
              offset: 85,
              duration: 6000,
            });
          }
        })
        .finally(() => {
          this.send_data = false;
          this.$emit("closeModal");
        });
    },
  },
  mounted() {
    this.valuesForm.desc = this.$store.getters["Orders/OrderNote"];
  },
};
</script>

<style scoped></style>
