<template>
  <div class="method_receiving_order_block_center">
    <div class="method_receiving_order_block_center_left">
      <ul id="Pickup"></ul>
    </div>
    <div class="method_receiving_order_block_center_right">
      <v-map
        :points="points"
        id_list="Pickup"
        @selectedDataId="selectedDataId"
      />
    </div>
  </div>
</template>

<script>
import VMap from "@/components/v-map";
export default {
  name: "MethodReceivingOrderPickup",
  props: {
    points: {
      type: Object,
      default() {
        return {};
      },
    },
    id_list: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      data_id: 0,
    };
  },
  components: { VMap },
  methods: {
    selectedDataId(data_id) {
      this.data_id = parseInt(data_id);
      this.$emit("selectedDataId", this.data_id);
    },
  },
  mounted() {
    this.$store.commit("Orders/requestDataSetReceive", undefined);
  },
};
</script>

<style scoped></style>
