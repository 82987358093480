<template>
  <div class="modal-content">Тело модального окна</div>
</template>

<script>
export default {
  name: "ModalDefault",
};
</script>

<style scoped></style>
