<template>
  <el-form
    :rules="rules"
    ref="AddLegalEntity"
    :label-position="labelPosition"
    :model="valuesForm"
    size="medium"
    @submit.native.prevent="onEnter"
    class="modal_body_add_legal_entity"
    :disabled="send_data"
    :status-icon="false"
  >
    <el-form-item prop="name">
      <el-input
        v-model="customerName"
        placeholder="Название компании"
        :disabled="true"
      ></el-input>
    </el-form-item>
    <el-form-item prop="inn">
      <el-input
        v-model="valuesForm.inn"
        maxlength="12"
        minlength="10"
        placeholder="ИНН"
        show-word-limit
        @keypress="isNumber($event)"
      ></el-input>
    </el-form-item>
    <div class="add_legal_entity_info">
      <p v-for="(desc, i) in COMPANY_DESC" :key="i">
        {{ desc }}
      </p>
    </div>
    <!--    <el-form-item prop="bik">-->
    <!--      <el-input-->
    <!--        v-model="valuesForm.bik"-->
    <!--        placeholder="БИК"-->
    <!--        maxlength="9"-->
    <!--        minlength="9"-->
    <!--        show-word-limit-->
    <!--        :disabled="!successInn"-->
    <!--        @input="onInputBik($event)"-->
    <!--      ></el-input>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item prop="current_account_number">-->
    <!--      <el-input-->
    <!--        v-model="valuesForm.current_account_number"-->
    <!--        placeholder="Номер расчетного счета"-->
    <!--        maxlength="20"-->
    <!--        minlength="20"-->
    <!--        show-word-limit-->
    <!--        :disabled="!successInn"-->
    <!--      ></el-input>-->
    <!--    </el-form-item>-->
    <el-button
      native-type="submit"
      size="medium"
      style="width: 132px; margin-top: 6px"
      @click.prevent="submitForm('AddLegalEntity')"
      :loading="send_data"
      :disabled="!successInn"
      plain
      >Добавить</el-button
    >
  </el-form>
</template>

<script>
export default {
  name: "ModalAddLegalEntity",
  data() {
    let validateINN = (rule, value, callback) => {
      if (value.length === 10 || value.length === 12) {
        callback();
      } else {
        callback(new Error("Формат ИНН: 10 или 12 цифр"));
      }
    };
    let innExists = (rule, value, callback) => {
      this.onInputInn(rule, value, callback);
    };
    return {
      COMPANY_DESC: "",
      send_data: false,
      successInn: false,
      valuesForm: {
        name: "Рекламное агентство 'РА4'",
        inn: "",
        bik: "",
        cc: "",
      },
      labelPosition: "top",
      rules: {
        inn: [
          {
            required: true,
            message: "Пожалуйста укажите ИНН",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            validator: validateINN,
            trigger: "change",
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: innExists,
            trigger: "change",
            transform(value) {
              return value.trim();
            },
          },
        ],
        bik: [
          {
            required: true,
            message: "Пожалуйста укажите БИК",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            min: 9,
            message: "Формат БИК: 9 цифр",
            trigger: "change",
          },
        ],
        current_account_number: [
          {
            required: true,
            message: "Пожалуйста укажите Расчетный счет",
            trigger: "change",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Разрешены только цифры",
            trigger: "change",
          },
          {
            min: 20,
            message: "Формат Расчетный счет: 20 цифр",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    customerName() {
      return this.$store.getters["Main/customerName"];
    },
    customerID() {
      return this.$store.getters["Main/customerID"];
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addCustomer();
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
    onInputInn(rule, value, callback) {
      if (
        (value.length === 10 || value.length === 12) &&
        this.valuesForm.inn !== ""
      ) {
        let request = {
          add: {
            customer: this.customerID,
            name: this.customerName,
            inn: this.valuesForm.inn,
          },
          oid: false,
        };
        let vm = this;
        this.openLoading("Поиск...");
        this.$store
          .dispatch("Orders/checkCompany", request)
          .then((response) => {
            if (
              response &&
              response.data.status &&
              response.data.status === 2000 &&
              response.data.response.companies[0]
            ) {
              vm.COMPANY_DESC = response.data.response.companies[0];
              vm.successInn = true;
              callback();
            } else if (response.data.status && response.data.status === 6007) {
              vm.successInn = false;
              this.COMPANY_DESC = {};
              callback(new Error("Поиск по ИНН не дал результатов"));
            }
          })
          .catch(() => {
            vm.successInn = false;
            this.COMPANY_DESC = {};
            callback(new Error("Поиск по ИНН не дал результатов"));
          })
          .finally(() => {
            this.closeLoading();
          });
      } else {
        this.COMPANY_DESC = {};
      }
    },
    onInputBik(e) {
      if (
        e.length === 9 &&
        this.customerName !== "" &&
        this.valuesForm.inn !== ""
      ) {
        let request = {
          add: {
            customer: this.customerID,
            name: this.customerName,
            inn: this.valuesForm.inn,
            bik: this.valuesForm.bik,
          },
          oid: false,
        };
        this.$store
          .dispatch("Orders/checkCompany", request)
          .then((response) => {
            if (
              response &&
              response.data.status &&
              response.data.status === 2000
            ) {
              //console.log(response.data);
            }
          });
      }
    },
    addCustomer() {
      this.send_data = true;
      let oid = false;
      if (this.$route.name === "order") {
        oid = this.$route.params.oId;
      }
      let request = {
        add: {
          customer: this.customerID,
          name: this.customerName,
          inn: this.valuesForm.inn,
          update: true,
        },
        oid: oid,
      };
      this.$store
        .dispatch("Orders/checkCompany", request)
        .then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            this.$emit("closeModal");
            this.$notify({
              title: "Success",
              message: "Заказчик успешно добавлен!",
              type: "success",
              offset: 85,
              duration: 6000,
            });
          }
        })
        .finally(() => {
          this.send_data = false;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".add_legal_entity_info",
        text: text,
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 1)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
