import { render, staticRenderFns } from "./MethodReceivingOrderCdek.vue?vue&type=template&id=260132dd&scoped=true&"
import script from "./MethodReceivingOrderCdek.vue?vue&type=script&lang=js&"
export * from "./MethodReceivingOrderCdek.vue?vue&type=script&lang=js&"
import style0 from "./MethodReceivingOrderCdek.vue?vue&type=style&index=0&id=260132dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260132dd",
  null
  
)

export default component.exports