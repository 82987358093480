<template>
  <el-form
    ref="CommentsCrm"
    :model="valuesForm"
    size="medium"
    @submit.native.prevent="onEnter"
    class="modal_body_comments_crm"
    :disabled="send_data"
    :status-icon="false"
  >
    <div class="block_list_comments">
      <div
        v-for="(comment, index) in propsForm"
        :key="index"
        class="item_comment"
      >
        <p>
          {{ comment.item }}
        </p>
        <div class="item_comment_info">
          <span class="item_comment_info_date">{{ comment.date }}</span>
        </div>
      </div>
    </div>
    <el-form-item class="block_textarea_comment">
      <el-input
        type="textarea"
        :rows="4"
        maxlength="150"
        :autosize="{ minRows: 4, maxRows: 6 }"
        show-word-limit
        placeholder="Напишите комментарий к расчету"
        v-model="valuesForm.comment"
      >
      </el-input>
    </el-form-item>
    <el-button
      native-type="submit"
      size="medium"
      style="width: 132px"
      @click.prevent="submitForm('CommentsCrm')"
      :loading="send_data"
      plain
      >Добавить</el-button
    >
    <!--    <div class="block_textarea_comment"></div>-->
    <!--    <div class="block_button"></div>-->
  </el-form>
</template>

<script>
export default {
  name: "ModalCommentsCrm",
  props: {
    propsForm: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      valuesForm: {
        comment: "",
      },
      send_data: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.$emit("closeModal");
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
  },
};
</script>

<style scoped></style>
